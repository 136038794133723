// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alternate-feeds-js": () => import("./../../../src/pages/alternate-feeds.js" /* webpackChunkName: "component---src-pages-alternate-feeds-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-deep-linking-js": () => import("./../../../src/pages/deep-linking.js" /* webpackChunkName: "component---src-pages-deep-linking-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-donations-js": () => import("./../../../src/pages/donations.js" /* webpackChunkName: "component---src-pages-donations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-paged-feeds-js": () => import("./../../../src/pages/paged-feeds.js" /* webpackChunkName: "component---src-pages-paged-feeds-js" */),
  "component---src-pages-podlove-podcast-publisher-js": () => import("./../../../src/pages/podlove-podcast-publisher.js" /* webpackChunkName: "component---src-pages-podlove-podcast-publisher-js" */),
  "component---src-pages-podlove-podcast-publisher-plus-js": () => import("./../../../src/pages/podlove-podcast-publisher-plus.js" /* webpackChunkName: "component---src-pages-podlove-podcast-publisher-plus-js" */),
  "component---src-pages-podlove-subscribe-button-js": () => import("./../../../src/pages/podlove-subscribe-button.js" /* webpackChunkName: "component---src-pages-podlove-subscribe-button-js" */),
  "component---src-pages-podlove-web-player-js": () => import("./../../../src/pages/podlove-web-player.js" /* webpackChunkName: "component---src-pages-podlove-web-player-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-simple-chapters-js": () => import("./../../../src/pages/simple-chapters.js" /* webpackChunkName: "component---src-pages-simple-chapters-js" */),
  "component---src-pages-specifications-js": () => import("./../../../src/pages/specifications.js" /* webpackChunkName: "component---src-pages-specifications-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-article-template-js": () => import("./../../../src/templates/blog-article-template.js" /* webpackChunkName: "component---src-templates-blog-article-template-js" */)
}

